import React, { useState } from 'react';
import '../../styles/components_style/shoppingCart.scss'
import CartItem from './CartItem';
import { useTravel } from '../../context/TravelCartContext';

const ShoppingCart = () => {

    const {removeFromCart, totalCartPrice, totalItems, cartItems, dateA, dateD} = useTravel();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const displayModal = () => {
        if (isModalOpen === false) {
            setIsModalOpen(true);
        } else {
            setIsModalOpen(false);
        }
    }

    return (
        <>
            <div className='shoppingList' onClick={displayModal}>
                <div className='shoppingList_textBox'>
                    {isModalOpen === false ? (
                        <img className='shoppingList_svgIcon' src="https://res.cloudinary.com/dpistjmej/image/upload/v1720952312/shopping-cart-1-svgrepo-com_xphrwu.svg" alt="" />
                    ) : (
                        <p>clickez ici pour fermer le panier</p>
                    )}
                </div>
                <div className='shoppingList_priceBox'>
                    <p>{totalCartPrice} €</p>
                </div>
                <div className='itemCountBubble'><p>{totalItems}</p></div>
            </div>
            {isModalOpen ? (
                <div className="containerShoppingCart">
                    <div className="containerShoppingCart_margin">
                        <div className="containerShoppingCart_titleAndText">
                            <h3>Votre voyage</h3>
                            <p>Voici votre panier personnel, ici vous retrouverez tout ce que vous avez choisie</p>
                        </div>
                        <div className="containerShoppingCart_date-range">
                            <span className="containerShoppingCart_date-range_spanLeft">Du <strong>{dateA}</strong></span>
                            <span>{" au "}<strong>{dateD}</strong></span>
                        </div>
                        {cartItems.map((item, key) => {
                            return (
                                <CartItem
                                    sourceImg={item.imgUrl}
                                    title={item.title}
                                    nombreDePersonnes={item.numberOfPersons}
                                    nombreChildren={item.numberOfChildren}
                                    prix={item.totalPrice}
                                    key={key}
                                    index={key}
                                    removeFunction={removeFromCart}
                                    type={item.type}
                                    isOnReservationPage={false}
                                />
                            )
                        })}

                        <div className="containerShoppingCart_total">
                            <p>Total :</p>
                            <p className="containerShoppingCart_total_amount">{totalCartPrice} €</p>
                        </div>
                    </div>

                </div>
            ):null}
        </>
    );
};

export default ShoppingCart;