import { CheckCircle, ChevronRight } from "lucide-react";
import '../../styles/components_style/progressBar.scss';
import { useTravel } from "../../context/TravelCartContext";

export default function ProgressBar({succesBtnFunction}) {
  const {step, setStep} = useTravel();

  const steps = [
    { label: "Dates", icon: "📅" },
    { label: "Chambre", icon: "🛏️" },
    { label: "Formulaire", icon: "📝" }
  ];

  return (
    <div className="progress-bar">
      {step < steps.length ? (
        <>
          {steps.map((s, index) => (
            <div key={index} className="step">
                <span className="icon">{s.icon}</span>
                <div
                  className={`circle ${index <= step ? "active" : ""}`}
                  onClick={() => setStep(index)}
                >
                  {index < step ? <CheckCircle size={14} /> : <span>⬜</span>}
                </div>
              {index < steps.length - 1 && <ChevronRight />}
            </div>
          ))}
        </>
      ) : (
        <button className="button-primary" onClick={() => {
          setStep(0);
          succesBtnFunction();
        }}>
          Obtenir le voyage
        </button>
      )}
    </div>
  );
}

