import React, { useState } from 'react';
import '../../styles/components_style/cartItem.scss';
import { Button } from '@mui/material';
import { useTravel } from '../../context/TravelCartContext';

const ActivityAddOns = ({ sourceImg, title, type, activityLocation, prix, activity_id }) => {

    const [btnAddStatus, setAddBtnStatus] = useState(false);

    const { addActivityToShoppingCart, getPersonalisedSuccesAlert} = useTravel();

    function handleClickAddToCart() {
        if (btnAddStatus === false) {
            getPersonalisedSuccesAlert("Activité ajouté !");
            addActivityToShoppingCart(title, sourceImg, type, activityLocation, prix, activity_id);
            setAddBtnStatus(true);
        } else {
            setAddBtnStatus(false);
        }

    }

    function getItemDescription() {

        switch (type) {
            case 'activity':
                return (
                    <>
                        <div className='containerShoppingCart_item_bouble_content_text_activity'>
                            <p>payment sur place</p>
                        </div>
                    </>
                )
            case 'activity_coco':
                return (
                    <>
                        <div className='containerShoppingCart_item_bouble_content_text_activity'>
                            <p><strong>{"organisé par cocobino"}</strong></p>
                        </div>
                    </>
                )
            default:
                return null;
        }
    }

    return (
        <div className="containerShoppingCart_item">
            <div className="containerShoppingCart_item_bouble">
                <div className='containerShoppingCart_item_bouble_imgContainer'>
                    <img className='containerShoppingCart_item_bouble_imgContainer_img' src={sourceImg} alt="Chambre du compté" />
                </div>
                <div className="containerShoppingCart_item_bouble_content">
                    <div className="containerShoppingCart_item_bouble_content_title">
                        <strong><p>{title}</p></strong>
                    </div>
                    <div className='containerShoppingCart_item_bouble_content_text'>
                        {getItemDescription()}
                        {btnAddStatus === false ? (
                            <Button onClick={() => { handleClickAddToCart() }} variant="contained" size='small' style={{ 'backgroundColor': 'black', 'fontSize': '11px', 'boxShadow': 'none', width: "138px" }}> <p>Ajouter au voyage</p> </Button>
                        ) : (
                            <Button onClick={() => { handleClickAddToCart() }} disabled={true} variant="contained" size='small' style={{ 'fontSize': '11px', 'boxShadow': 'none', width: "138px" }}> <p>ajouté</p> </Button>
                        )}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default ActivityAddOns;