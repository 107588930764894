import React, {  } from "react";
import { Routes, Route } from 'react-router-dom';
import About from "./pages/About";
import Home from "./pages/Home";
import Boutique from "./pages/Boutique";
import Contact from "./pages/Contact";
import ArticlePage from "./pages/ArticlePage";
import Article from "./pages/article/Article";
import ArticleVoyageResponsable from "./pages/article/ArticleVoyageResponsable";
import PageVenteArticleFinlande from "./pages/article/pageVente/PageVenteArticleFinlande";
import PageVenteArticleBelgique from "./pages/article/pageVente/PageVenteArticleBelgique";
import PageVenteArticleCorse from "./pages/article/pageVente/PageVenteArticleCorse";
import PageVenteArticleSuisse from "./pages/article/pageVente/PageVenteArticleSuisse";
import TravelPage from "./pages/sejours/TravelPage";
import { TravelCartContext } from "./context/TravelCartContext";
import Remerciement from "./pages/Remerciement";
import ConditionsUtilisation from "./pages/ConditionsUtilisation";
import ConditionsVente from "./pages/ConditionsVente";
import PolitiqueConfidentialite from "./pages/PolitiqueConfidentialite";
import ChoixVoyage from "./pages/ChoixVoyage";

const App = () => {


  return (

      <TravelCartContext>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/boutique" element={<Boutique />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Home />} />
          <Route path="/articles" element={<ArticlePage />} />
          <Route path="/article" element={<Article />} />
          <Route path="/articles/article-voyageResponsable" element={<ArticleVoyageResponsable />} />
          <Route path="/articles/article-voyageResponsable/pageVenteFinlande" element={<PageVenteArticleFinlande />} />
          <Route path="/articles/article-voyageResponsable/pageVenteBelgique" element={<PageVenteArticleBelgique />} />
          <Route path="/articles/article-voyageResponsable/pageVenteCorse" element={<PageVenteArticleCorse />} />
          <Route path="/articles/article-voyageResponsable/pageVenteSuisse" element={<PageVenteArticleSuisse />} />

          <Route path="/boutique/:hotel_title/:hotel_id" element={<TravelPage />} />

          <Route path="/thankyou" element={<Remerciement />} />
          <Route path="/condition_utilisation" element={<ConditionsUtilisation />} />
          <Route path="/condition_vente" element={<ConditionsVente />} />
          <Route path="/politiqueDeConfidence" element={<PolitiqueConfidentialite />} />
          <Route path="/choix-voyage" element={<ChoixVoyage />} />
        </Routes>

      </TravelCartContext>
  )
}

export default App;