import React from 'react';
import '../styles/components_style/BoxDeVoyage.scss';
//import stars from '../styles/assets/logo/etoile.svg';
import { useTravel } from '../context/TravelCartContext';

const BoxDeVoyage = ({ hotel, refBox, displayOffer }) => {
    const { handleClickNavigateForBoxDeVoyageVersTravelPage } = useTravel();

    const hotelInformation = {
        "title": hotel.title,
        "img": hotel.imgUrlArray[0],
        "location": hotel.location,
        "description": hotel.description,
        "price": hotel.rooms[0].price
    };

    const handleClick = () => {
        handleClickNavigateForBoxDeVoyageVersTravelPage(hotel);
    };

    /*const star = Array.from({ length: hotel.numberOfStars }, (_, index) => (
        <img src={stars} alt="" key={index} className='sectionBoxDeVoyage__box__stars__img' id='stars' />
    ));*/

    return (
        <div ref={refBox}>
            {/*<div className="badge">
                <p>Pack Romance Offert</p>
            </div>*/}
            <div className="travel-box" onClick={handleClick}>
                
                <img src={hotelInformation.img} alt={hotelInformation.title} className="travel-box__image" />
                <div className="travel-box__content">
                    <div>
                        <div className="travel-box__titleContainer">
                            <h2 className="travel-box__title">{hotelInformation.title}</h2>
                        </div>
                        <p className="travel-box__price">{hotelInformation.price} €</p>
                    </div>
                    <button className="travel-box__button" onClick={handleClick} >Réserver</button>
                </div>
            </div>
        </div>
    );
};

export default BoxDeVoyage;