import React from 'react';
import '../styles/pages_style/Boutique.scss';
import BoxDeVoyage from '../components/BoxDeVoyage';

import COCOBINO_HOTELS from "../local_db/db.json";

const Boutique = () => {

    return (
        <div>
            <div className='descriptionContainer'>
                <h2>Nos voyages personnalisable</h2>
                <p>Choisissez un hotel parmi notre selection</p>    
            </div>
            
            <section className='sectionBoxDeVoyage'>
                
                <div className='sectionBoxDeVoyage__PageViewContainer'>
                    {
                        COCOBINO_HOTELS.hotels.map((hotel_db, key) => {
                            return (
                                <BoxDeVoyage
                                    hotel={hotel_db}
                                    key={key}
                                    displayOffer={true}
                                />
                            );
                        })
                    }
                </div>
            </section>
        </div>
    );
};


export default Boutique;