import React from 'react';
import HomePage from "../pages/HomePage"
import Footer from "../components/Footer";
import Header2 from "../components/Header2";
import ScrollToTop from "../components/ScrollTop";

const Home = () => {
    document.title = 'cocobino - Un voyage au Luxembourg personnalisé et authentique';

    /*const messageBanner = (
        <>
            Surprenez votre moitié avec <strong>champagne</strong>, <strong>vin</strong> et <strong>un Pack Romance</strong> offert !
        </>
    );*/

    return (
        <div>
            <ScrollToTop />
            <Header2 />
            <HomePage />
            <Footer />
        </div>
    );
};

export default Home;
