import React from 'react';
import Boutique from './Boutique';

const HomePage = () => {

    const handleInstaIconClick = () => {
        window.location.href = 'https://www.instagram.com/cocobino/';
    }

    return (
        <>
            <div className="home-section">
                <div className="overlay">
                    <h1>Vivez l'amour avec Cocobino</h1>
                    <p>
                        Expert en voyages romantiques au Luxembourg, chaque détail est conçu pour une expérience unique. Profitez d'un service de qualité et d'avantages exclusifs Coco. Créons ensemble votre voyage de rêve.
                    </p>
                    <button className="instagram-button" onClick={handleInstaIconClick}>
                        <img
                            src="https://res.cloudinary.com/dpistjmej/image/upload/v1740916045/instagram-color-svgrepo-com_ojpzmu.svg"
                            alt="Instagram"
                            className="instagram-icon"
                            style={{ width: '30px', height: '30px' }}
                        />
                    </button>
                </div>
            </div>
            <Boutique />
        </>
    );
};

export default HomePage;