import React from 'react';
import Header2 from '../components/Header2';
const ChoixVoyage = () => {
    return (
        <>
            <Header2 />
            <div style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                minHeight: '100vh', backgroundColor: 'white', padding: '20px', textAlign: 'center'
            }}>
                <h1 style={{ fontSize: '32px', fontWeight: 'bold', color: '#333', marginBottom: '10px' }}>
                    Planifiez votre voyage idéal
                </h1>
                <p style={{ fontSize: '18px', color: '#555', marginBottom: '20px' }}>
                    Préférez-vous un voyage déjà organisé ou souhaitez-vous le personnaliser ?
                </p>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <button
                        style={{ padding: '10px 20px', fontSize: '16px', backgroundColor: '#007bff', color: 'white', border: 'none', cursor: 'pointer' }}
                        onClick={() => window.location.href = '/'}
                    >
                        Voyage déjà fait
                    </button>
                    <button
                        style={{ padding: '10px 20px', fontSize: '16px', border: '1px solid #007bff', color: '#007bff', cursor: 'pointer' }}
                        onClick={() => window.location.href = '/boutique'}
                    >
                        Organiser le voyage
                    </button>
                </div>
            </div>
        </>
    );
};

export default ChoixVoyage;