import React, { createContext, useState, useContext, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from '../components/Alert';
import { differenceInDays } from "date-fns";
import SussesAlert from '../components/SussesAlert';

const TravelContext = createContext();

export const TravelCartContext = ({ children }) => {
    //
    // variables 
    //
    const navigate = useNavigate();
    const [cartItems, setCartItems] = useState([]);
    const [messageAlert, setMessageAlert] = useState([]);
    const [openAlert, setOpenAlert] = useState(false);
    const [openAlertS, setOpenAlertS] = useState(false);

    const [dateD, setDateD] = useState(() => localStorage.getItem("dateD") || null);
    const [dateA, setDateA] = useState(() => localStorage.getItem("dateA") || null);
    // - progress bar 
    const [step, setStep] = useState(0);

    //----------------------------------
    // event Tracking code -- datalayer 
    //----------------------------------
    /*window.dataLayer = window.dataLayer || [];
    const trackEvent = (eventName, eventData) => {
        window.dataLayer.push({
            event: eventName,
            ...eventData,
        });
    };*/

    function getPersonalisedAlert(message) {
        setMessageAlert(message);
        setOpenAlert(true);
    }

    function getPersonalisedSuccesAlert(message) {
        setMessageAlert(message);
        setOpenAlert(true);
        setOpenAlertS(true);
    }

    function getHotel() {
        return JSON.parse(localStorage.getItem("hotelFTP")) || null;
    }

    const [hotelForTravelPage, setHotel] = useState(getHotel())

    const checkOutDates = () => {
        if (dateD === null && dateA === null) {
            return false
        } else {
            return true;
        }
    }

    const howManyNights = useMemo(() => {
        if (!dateA || !dateD) {
            // Si l'une des dates est invalide, retourne 0
            return 0;
        }
        try {
            // Parse les dates au format "dd/MM/yyyy"
            console.log(dateA," ", dateD);
            
            // Calculer la différence en jours
            const numberOfNights = differenceInDays(dateD, dateA) - 1;
            return Math.max(0, numberOfNights);
        } catch (error) {
            console.error("Erreur lors de l'analyse des dates :", error);
            return 0; // Retourne 0 en cas d'erreur
        }

    }, [dateA, dateD]);

    function handleClickNavigateForBoxDeVoyageVersTravelPage(hotel) { // ok

        localStorage.setItem("hotelFTP", JSON.stringify(hotel));
        setHotel(hotel);
        navigate(`/boutique/${hotel.title}/${hotel.id}`);

    }

    const [travelResumeInLocal, setTravelResumeInLocal] = useState(() => {
        const storedData = localStorage.getItem("travelResume");
        return storedData ? JSON.parse(storedData) : null;
    });

    useEffect(() => {
        if (travelResumeInLocal === null) {
            localStorage.setItem("travelResume", travelResumeInLocal);
        }

    }, [travelResumeInLocal]);

    function setTravelResumeForTravelPage(hotelLocation, dateDepard, dateArrive, itemList, totalPrice, nights) {
        let trvR = {
            "locationHotel": hotelLocation,
            "dateDepard": dateDepard,
            "dateArrive": dateArrive,
            "itemList": itemList,
            "totalPrice": totalPrice,
            "nuits": nights
        }
        localStorage.setItem("travelResume", JSON.stringify(trvR));
        setTravelResumeInLocal(trvR);
    }
    //------------------------------
    //------------------------------
    // HOTEL AND CLIENT RESERVATION JOURNEY
    //------------------------------
    //------------------------------

    const addRoomToShoppingCart = (numberOfPerson, title, price, roomUrlImg, type,id) => {
        if (numberOfPerson === 0) {
            console.log("YOU NEED TO CHOOSE HOW MANY PERSON");
        } else {
            let room = {
                "title": title,
                "numberOfPersons": numberOfPerson,
                "totalPrice": price,
                "imgUrl": roomUrlImg,
                "type": type,
                "id": id
            }
            setCartItems([...cartItems, room]);
        }
    } // ok

    const addActivityToShoppingCart = (title, roomUrlImg, type, activityLocation, price, id) => {

        let activity = {
            "title": title,
            "imgUrl": roomUrlImg,
            "type": type,
            "locationActivite": activityLocation,
            "totalPrice": price,
            "id": id
        }
        setCartItems([...cartItems, activity]);

    }

    const addSuppToShoppingCart = (title, price, roomUrlImg, type, champagne, cardMessage,id) => {

        let supp = {
            "title": title,
            "totalPrice": price,
            "imgUrl": roomUrlImg,
            "type": type,
            "champagne": champagne,
            "cardMessage": cardMessage,
            "id": id
        }
        setCartItems([...cartItems, supp]);
    }

    const removeFromCart = (index) => {
        setCartItems(cartItems.filter((_, i) => i !== index));
    }

    const totalCartPrice = useMemo(() => {
        return cartItems.reduce((total, item) => total + item.totalPrice, 0)
    }, [cartItems]);

    const totalItems = useMemo(() => {
        return cartItems.length;
    }, [cartItems]);

    const getUserTravelReady = () => {
        if (checkOutDates() === false) {
            setMessageAlert("Vous devez choissir des dates");
            setOpenAlert(true);
        } else if (cartItems.length === 0) {
            setMessageAlert("le panier est vide");
            setOpenAlert(true);
        } else {
            setTravelResumeForTravelPage(hotelForTravelPage.location, dateA, dateD, cartItems, totalCartPrice, howManyNights);
        }
    }


    return (
        <TravelContext.Provider value={{
            addRoomToShoppingCart, addActivityToShoppingCart, addSuppToShoppingCart, removeFromCart,
            totalCartPrice, totalItems, cartItems, getUserTravelReady,
            setDateA, setDateD, dateA, dateD,
            setHotel, hotelForTravelPage, checkOutDates, howManyNights,
            handleClickNavigateForBoxDeVoyageVersTravelPage,
            travelResumeInLocal, setTravelResumeForTravelPage,
            getPersonalisedAlert, getPersonalisedSuccesAlert, step, setStep
        }}>
            {
                <>
                    <Alert message={messageAlert} openState={openAlert} setOpenFunction={setOpenAlert} />
                    <SussesAlert message={messageAlert} openState={openAlertS} setOpenFunction={setOpenAlertS} />
                    {children}
                </>
            }
        </TravelContext.Provider>
    );
};

export const useTravel = () => useContext(TravelContext);