import React from 'react';
import Snackbar from "@mui/material/Snackbar";

const SussesAlert = ({message, openState, setOpenFunction}) => {
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpenFunction(false); // Fermer l'alerte
    };
  

    return (
        <Snackbar
        open={openState}
        autoHideDuration={1900}  // La durée avant que l'alerte ne disparaisse (en millisecondes)
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Position de l'alerte
      >
        <div style={{height:"auto",width:"auto",backgroundColor:"#54e879",padding:"5px",borderRadius:"9px",color:"white"}}>
          <p>{message}</p>
        </div>
      </Snackbar>
    );

};

export default SussesAlert;