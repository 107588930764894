import React , {useEffect} from 'react';
import ShoppingCart from '../../components/newComponents/ShoppingCart';
import Button from '@mui/material/Button';
import RoomBouble from '../../components/newComponents/RoomBouble';
import { TravelInformation } from '../../components/newComponents/TravelInformation';
import "../../styles/pages_style/TravelPage.scss";
import Header2 from '../../components/Header2';
import Footer from '../../components/Footer';
import { useParams } from 'react-router-dom';
import ScrollToTop from '../../components/ScrollTop';
import SupplementBubble from '../../components/newComponents/SupplementBubble';
import SUPP from '../supp.json';
import { useTravel } from '../../context/TravelCartContext';
import { useForm } from 'react-hook-form';
import { TextField, Checkbox, FormControlLabel } from '@mui/material';
import COCOBINO_ACTIVITY from "../../local_db/db.json";
import { useNavigate } from 'react-router-dom';
import ActivityAddOns from '../../components/newComponents/ActivityAddOns';
import ProgressBar from '../../components/newComponents/ProgressBar';

const TravelPage = () => {

    const { howManyNights, hotelForTravelPage, step, setStep } = useTravel();
    const { hotel_title } = useParams();

    document.title = `Page Hotel ${hotel_title}`;
    let supp = SUPP;

    const navigate = useNavigate();
    const { travelResumeInLocal, getPersonalisedSuccesAlert, getPersonalisedAlert, getUserTravelReady } = useTravel();

    let url2 = "https://cocobino-backend.onrender.com/demandChecking";
    //let url1 = "http://localhost:5000/demandChecking"
    // Initialisation des hooks de formulaire
    const { register, handleSubmit, formState: { errors, isValid } } = useForm({
        mode: "onChange"
    });
    function handleReserverVoyageClick(data) {
        getUserTravelReady();
        if (travelResumeInLocal.itemList.length !== 0 && data !== null) {
            fetch(url2, {

                method: "POST",
                headers: {
                    'Content-type': "application/json"
                },
                body: JSON.stringify({
                    clientInfos: data,
                    clientTravel: travelResumeInLocal
                })
            })
                .then(res => res.json())
                .then((data) => {
                    console.log(data)
                    getPersonalisedSuccesAlert("Votre demande a été bien envoyée");
                    navigate("/thankyou");
                })
                .catch(err => console.log(err));
        } else {
            getPersonalisedAlert("erreur lors de l'envoie de votre demande");
        }
    }

    const onSubmit = (data) => {
        handleReserverVoyageClick(data);
    };

    useEffect(() => {
        if(isValid && step === 2){
            setStep(step + 1);
            getPersonalisedSuccesAlert("Formulaire validé 🎉");
        }
    })

    return (
        <>
            <Header2 />
            <ScrollToTop />
            <ProgressBar succesBtnFunction={onSubmit}/>
            <div className='connexionPage'>
                <ShoppingCart />
                <TravelInformation hotelTitle={hotel_title} testImagesArrays={hotelForTravelPage.imgUrlArray} testHotelDescription={hotelForTravelPage.description} testRoomAdvantage={hotelForTravelPage.advantages}
                    hotelLocation={hotelForTravelPage.location} />
                <div className='title_headSection'>
                    <h3>Etape 2 : Choisissez une chambre</h3>
                </div>
                <div className='roomList'>
                    <div className='roomList__container'>
                        {hotelForTravelPage.rooms.map((room, index) => {
                            return (
                                <RoomBouble
                                    roomTitle={room.titleRoom}
                                    roomImg={room.imgUrlArrayRoom}
                                    roomAdvantages={room.advantageRoom}
                                    roomPrice={room.price}
                                    roomDescription={room.descriptionRoom}
                                    roomMaxOfPerson={room.maxOfPerson}
                                    key={index}
                                    nights={howManyNights}
                                />
                            )
                        })}
                    </div>
                </div>

                <div className='title_headSection'>
                    <h3>Etape 3 : Prenez une option cocobino</h3>
                </div>
                <div className='roomList'>
                    <div className='roomList__container'>
                        {supp.map((elmnt, key) => {
                            return (
                                <SupplementBubble
                                    packTitle={elmnt.titre}
                                    packageDescription={elmnt.grandDescription}
                                    packageImg={elmnt.img}
                                    key={key}
                                    packagePrice={elmnt.prix}
                                    packageAVTDescription={elmnt.description}
                                    displayPromotion={elmnt.displayOffer}
                                />
                            )
                        })}
                    </div>
                </div>
                <div className='title_headSection'>
                    <h3>Etape 4 : Remplisser le formulaire</h3>
                    <p variant="body2" className="description">
                        Cher client, nous vous prions de vérifier attentivement vos données. Cela est crucial pour prévenir tout problème éventuel. Merci pour votre attention !
                    </p>
                </div>

                <div className="app-container" >
                    <div className='main'>
                        <form className='main_inputForm' onSubmit={handleSubmit(onSubmit)}>
                            <TextField
                                label="Nom / Prénom"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                {...register("nom", { required: "Ce champ est requis" })}
                                error={!!errors.nom}
                                helperText={errors.nom ? errors.nom.message : ""}
                            />

                            <TextField
                                label="Telephone"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                {...register("telephone", { required: "Ce champ est requis" })}
                                error={!!errors.telephone}
                                helperText={errors.telephone ? errors.telephone.message : ""}
                            />

                            <TextField
                                label="@email"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                {...register("email", {
                                    required: "Ce champ est requis",
                                    pattern: {
                                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                        message: "Adresse email invalide"
                                    }
                                })}
                                error={!!errors.email}
                                helperText={errors.email ? errors.email.message : ""}
                            />

                            <TextField
                                label="n'hésitez pas à nous transmettre un message"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                multiline
                                rows={4}
                                {...register("message")}  // Ce champ est optionnel
                            />

                            <FormControlLabel
                                control={<Checkbox {...register("conditions", { required: "Vous devez accepter les conditions de vente" })} color="success" />}
                                label={<span>Vous avez lu et compris nos <p onClick={() => (window.open('/condition_vente'))} id='termOfSell_text'>conditions de vente</p></span>}
                            />
                            {errors.conditions && <p style={{ color: 'red' }}>{errors.conditions.message}</p>}
                        </form>
                        <div className='main_itemList'>
                            <h3>Ce que cocobino peut organiser pour vous :</h3>
                            <p variant="body2" className="description">
                                Vous ne payer rien, le payment se fait sur place et il est annulable à tout moment. Choisissez ce que vous voulez et nous nous occupons du reste.
                                S'il veulent ils peuvent les réserver eux meme, sinon, on leur propose les activitées, qu'ils peuvent faire s'ils le désirent ! 
                            </p>
                            <div className='main_itemList_itemSlide'>
                                {COCOBINO_ACTIVITY.activity !== null ? (
                                    COCOBINO_ACTIVITY.activity.map((item, key) => {
                                        if (item.localite === hotelForTravelPage.localite || item.localite === "ALL") {
                                            return (
                                                <ActivityAddOns
                                                    sourceImg={item.imgUrlArray.at(0)}
                                                    type={item.type}
                                                    nombreDePersonnes={2}
                                                    title={item.title}
                                                    activityLocation={item.location}
                                                    key={key}
                                                    prix={item.prixAdulte}
                                                    activity_id={item.id}
                                                />
                                            )
                                        } else {
                                            return null;
                                        }

                                    })
                                ) : (
                                    <p>votre panier est vide ! veuillez retourner vers la page prédénte</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='btnContainerEndOfPage'  >
                    <Button variant="contained" onClick={handleSubmit(onSubmit)} style={{ 'backgroundColor': 'black', 'boxShadow': 'none', 'width': '100%', marginTop: "35px" }}>passer à la réservation du voyage</Button>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default TravelPage;