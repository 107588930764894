import React, { useState } from "react";
import SliderForTravelPage from "./SliderForTravelPage";
import AdventageBubble from "./AdventageBubble";
import '../../styles/components_style/travelInformation.scss';
import TravelPlannerV2 from "../TravelPlannerV2"
import { Button } from "@mui/material";
import ButtonSimple from "../ButtonSimple";

export function TravelInformation({
    hotelTitle,
    testImagesArrays,
    testRoomAdvantage,
    testHotelDescription,
    hotelLocation
}) {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
        console.log("is clicked");

    }

    const closeModal = () => {
        setIsModalOpen(false);
        console.log(isModalOpen);
    }



    return (
        <>
            <div className='travelInformation'>
                <div className='travelInformation_bigScreenResp'>

                    <SliderForTravelPage imageList={testImagesArrays} />

                    <div className='travelInformation_bigScreenResp_titleAndAdventage'>
                        <div className="travelInformation_titleWrapper">
                            <h1>{hotelTitle}</h1>
                        </div>
                        <div className='travelInformation_details_location'>
                            <div className='travelInformation_details_location_iconContainer'>
                                <img className='travelInformation_details_location_iconContainer_img' src="https://res.cloudinary.com/dpistjmej/image/upload/v1721852254/location-svgrepo-com_1_xzjk8i.svg" alt="" />
                            </div>
                            <strong><p>{hotelLocation}</p></strong>
                            {/*
                    -- rendre la location plus petite !
                    */}
                        </div>
                        <AdventageBubble adventageList={testRoomAdvantage} />
                    </div>
                </div>

                <Button
                    variant="contained"
                    style={{ width: "fit-content", backgroundColor: "black", marginTop: "20px", borderRadius: "11px", height: "25px", fontSize: "12px" }}
                    onClick={openModal}
                >

                    <p>{"plus d'information sur l'hôtel"}</p>
                </Button>
                <div>

                </div>
                <div className='travelInformation_details'>
                    <h3>Etape 1 : Choisir vos dates</h3>
                </div>
                <TravelPlannerV2 />
            </div>
            {isModalOpen === true ? (
                <div className={`travelInformation_screenWrapper`}>
                    <div className="travelInformation_popupRoom">
                        <div className="buttonContainer">
                            <ButtonSimple label={"fermer"} number={1} onClickFunction={() => closeModal()} />
                        </div>
                        <div className='travelInformation_description'>
                            <h3 style={{ marginBottom: "20px" }}>Description de l'hébergement</h3>
                            <p>{testHotelDescription}</p>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
}
